<template>
  <div>
    <ManageAssistantTeacherBar />
    <v-container fluid>
      <base-material-card
        icon="mdi-account-star"
        :title="
          'ข้อมูลครูผู้ช่วย รายงานตัว รอบที่ : ' +
            periodassteachs.periodAssTeachTimes +
            ' / ' +
            periodassteachs.periodAssTeachYear
        "
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="text-right pa-2">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                    single-line
                    hide-details
                    dense
                    filled
                    class="mb-2"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        prepend-icon="mdi-calendar-clock"
                        outlined
                        v-model="
                          updatemanage_assistant_teachers.mt_date_app_now
                        "
                        label="กำหนดวันที่เริ่มปฏิบัติงาน ณ วิทยาลัย"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        locale="th"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="updatemanage_assistant_teachers.mt_date_app_now"
                      no-title
                      scrollable
                      locale="th"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        prepend-icon="mdi-calendar-clock"
                        outlined
                        v-model="
                          updatemanage_assistant_teachers.mt_date_app_postitionnow
                        "
                        label="กำหนดวันที่เริ่มปฏิบัติงาน ในตำแหน่งปัจจุบัน"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        locale="th"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="
                        updatemanage_assistant_teachers.mt_date_app_postitionnow
                      "
                      no-title
                      scrollable
                      locale="th"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    prepend-icon="mdi-compass-outline"
                    outlined
                    label="คำสั่งที่"
                    v-model="updatemanage_assistant_teachers.mt_order_app"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    prepend-icon="mdi-compass-outline"
                    :items="mt_status_select"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="สถานะ"
                    v-model="updatemanage_assistant_teachers.mt_status"
                    required
                    :rules="[v => !!v || '']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    prepend-icon="mdi-compass-outline"
                    :items="mt_examtype_select"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="วิธีการ"
                    v-model="updatemanage_assistant_teachers.mt_examtype"
                    required
                    :rules="[v => !!v || '']"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    prepend-icon="mdi-map-marker"
                    :items="mt_examgroup_select"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="เขตพื้นที่"
                    v-model="updatemanage_assistant_teachers.mt_examgroup"
                    required
                    :rules="[v => !!v || '']"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    prepend-icon="mdi-compass-outline"
                    :items="type_personnelApp"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="ประเภทการบรรจุ"
                    v-model="updatemanage_assistant_teachers.mt_type_personnel"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="text-center" >
                    <v-btn color="success" outlined @click="updatemanage_assistant_teachersDate()"> <v-icon>mdi-content-save</v-icon> ปรับปรุงข้อมูล</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" class="text-right pa-2">
              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="manage_assistant_teacherAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="manage_assistant_teacherCSV()"
              >
                <v-icon>mdi-file-import</v-icon>นำเข้าข้อมูล
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherTotal()"
              >
                <v-icon>mdi-clipboard-text</v-icon>สรุปจำนวนเรียกรายงานตัว
              </v-btn>
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teachers"
          :search="search"
          group-by="mt_id_branch"
          class="elevation-1"
          :item-class="row_classes"
          disable-pagination
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="24">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].mt_id_branch + " : " + items[0].name_branch }}
            </th>
          </template>

          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_examtype="{ item }">
            <v-chip dark color="purple" v-if="item.mt_examtype === 'select'">
              คัดเลือก
            </v-chip>
            <v-chip dark color="indigo" v-else>
              ทั่วไป
            </v-chip>
          </template>

          <template v-slot:item.mt_examgroup="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="purple"
                v-if="item.mt_examgroup === 'general'"
              >
                ทั่วไป
              </v-chip>
              <v-chip dark color="indigo" v-else>
                จชต.
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'transfer_position'"
              >
                ครูโอนย้าย
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'local_dev'"
              >
                ครูพัฒนาท้องถิ่น
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_admissions_status="{ item }">
            <v-icon
              v-if="item.mt_admissions_status === 'receipt'"
              color="green"
            >
              mdi-email-open
            </v-icon>
            <v-icon
              v-else-if="item.mt_admissions_status === 'missing'"
              color="red"
            >
              mdi-information
            </v-icon>

            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt' &&
                  item.mt_admissions_status !== 'missing'
              "
              color="warning"
              @click.stop="manage_assistant_teacherEdit(item.mt_id)"
            >
              mdi-pencil
            </v-icon>

            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt' &&
                  item.mt_admissions_status !== 'missing'
              "
              color="red"
              @click.stop="manage_assistant_teacherDelete(item.mt_id)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addmanage_assistant_teacherdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addmanage_assistant_teacherdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addmanage_assistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เลขบัตรประชาชน"
                        v-model="addmanage_assistant_teacher.mt_id_card"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-autocomplete
                        :items="branchs"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชา"
                        v-model="addmanage_assistant_teacher.mt_id_branch"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_branch }} :
                              {{ item.name_branch }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ลำดับที่สอบได้"
                        v-model="addmanage_assistant_teacher.mt_sequence"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำนำหน้าชื่อ"
                        v-model="addmanage_assistant_teacher.mt_title_s"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อ"
                        v-model="addmanage_assistant_teacher.mt_frist_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="นามสกุล"
                        v-model="addmanage_assistant_teacher.mt_last_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันเกิด"
                        v-model="addmanage_assistant_teacher.mt_brith_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนเกิด"
                        v-model="addmanage_assistant_teacher.mt_brith_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีเกิด"
                        v-model="addmanage_assistant_teacher.mt_brith_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เบอร์โทร"
                        v-model="addmanage_assistant_teacher.mt_tel_p"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อย่อวุฒิการศึกษา"
                        v-model="addmanage_assistant_teacher.mt_ed_abb"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="สาขาวิชาเอก"
                        v-model="addmanage_assistant_teacher.mt_ed_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันที่บรรจุ"
                        v-model="addmanage_assistant_teacher.mt_appoin_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนที่บรรจุ"
                        v-model="addmanage_assistant_teacher.mt_appoin_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีที่บรรจุ"
                        v-model="addmanage_assistant_teacher.mt_appoin_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำสั่งที่"
                        v-model="addmanage_assistant_teacher.mt_order_app"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_status_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="สถานะ"
                        v-model="addmanage_assistant_teacher.mt_status"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examtype_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="วิธีการ"
                        v-model="addmanage_assistant_teacher.mt_examtype"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examgroup_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="เขตพื้นที่"
                        v-model="addmanage_assistant_teacher.mt_examgroup"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="ประเภทการบรรจุ"
                        v-model="addmanage_assistant_teacher.mt_type_personnel"
                        required
                      >
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="warning"
                large
                @click.stop="addmanage_assistant_teacherdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletemanage_assistant_teacherdialog -->
      <v-layout>
        <v-dialog
          v-model="deletemanage_assistant_teacherdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบรายการข้อมูลครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form
                    ref="deletemanage_assistant_teacherform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editmanage_assistant_teacher.mt_id_card +
                              " : " +
                              editmanage_assistant_teacher.mt_title_s +
                              editmanage_assistant_teacher.mt_frist_name +
                              " : " +
                              editmanage_assistant_teacher.mt_last_name
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deletemanage_assistant_teacherdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletemanage_assistant_teacherSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editmanage_assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_assistant_teacherdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editmanage_assistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="เลขบัตรประชาชน"
                        v-model="editmanage_assistant_teacher.mt_id_card"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        :items="branchs"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชา"
                        v-model="editmanage_assistant_teacher.mt_id_branch"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_branch }} :
                              {{ item.name_branch }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ลำดับที่สอบได้"
                        v-model="editmanage_assistant_teacher.mt_sequence"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำนำหน้าชื่อ"
                        v-model="editmanage_assistant_teacher.mt_title_s"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อ"
                        v-model="editmanage_assistant_teacher.mt_frist_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="นามสกุล"
                        v-model="editmanage_assistant_teacher.mt_last_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เบอร์โทร"
                        v-model="editmanage_assistant_teacher.mt_tel_p"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อย่อวุฒิการศึกษา"
                        v-model="editmanage_assistant_teacher.mt_ed_abb"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="สาขาวิชาเอก"
                        v-model="editmanage_assistant_teacher.mt_ed_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำสั่งที่"
                        v-model="editmanage_assistant_teacher.mt_order_app"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_status_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="สถานะ"
                        v-model="editmanage_assistant_teacher.mt_status"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examtype_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="วิธีการ"
                        v-model="editmanage_assistant_teacher.mt_examtype"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examgroup_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="เขตพื้นที่"
                        v-model="editmanage_assistant_teacher.mt_examgroup"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="ประเภทการบรรจุ"
                        v-model="editmanage_assistant_teacher.mt_type_personnel"
                        required
                      >
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                outlined
                @click.stop="editmanage_assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherCSVdialog -->
      <v-layout>
        <v-dialog
          v-model="manage_assistant_teacherCSVdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form
                    ref="manage_assistant_teacherCSVdialogform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            outlined
                            block
                            large
                            color="info"
                            to="/admin/manage_assistant_teacher_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV รายชื่อเรียกรายงานตัว ครูผู้ช่วย"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click.stop="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_assistant_teacherCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherCountDialog -->
      <v-layout>
        <v-dialog
          v-model="manage_assistant_teacherCountDialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="ข้อมูลสรุปครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersCount"
                  :items="manage_assistant_teacherCount"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="info"
                      @click.stop="
                        matBranchQuery(
                          item.mt_times,
                          item.mt_years,
                          item.mt_id_branch
                        )
                      "
                    >
                      mdi-account-search
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_assistant_teacherCountDialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ManageAssistantTeacherBar from "../../../components/admin/manageAssistantTeacherBar.vue";

export default {
  name: "HRvecManageAssistantTeacher",
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      file2: null,
      addmanage_assistant_teacherdialog: false,
      editmanage_assistant_teacherdialog: false,
      deletemanage_assistant_teacherdialog: false,
      manage_assistant_teacherCSVdialog: false,
      manage_assistant_teacherCountDialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      addmanage_assistant_teacher: {},
      editmanage_assistant_teacher: {},
      colleges: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "วิธีการ", align: "left", value: "mt_examtype" },
        { text: "ประเภทกลุ่ม", align: "left", value: "mt_examgroup" },
        { text: "สถานะ", align: "center", value: "mt_status" },
        /*   {
                    text: "การรายงานตัว",
                    align: "center",
                    value: "mt_admissions_status"
                }, */
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        },
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัสบัตร", align: "left", value: "mt_id_card" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "ลำดับที่", align: "left", value: "mt_sequence" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "นามสกุล", align: "left", value: "mt_last_name" },
        { text: "วันเกิด", align: "left", value: "mt_brith_day" },
        { text: "เดือนเกิด", align: "left", value: "mt_brith_month" },
        { text: "ปีเกิด", align: "left", value: "mt_brith_year" },
        { text: "โทร", align: "left", value: "mt_tel_p" },
        { text: "วุฒิการศึกษา", align: "left", value: "mt_ed_abb" },
        { text: "เอกวิชา", align: "left", value: "mt_ed_name" },
        { text: "วันที่บรรจุ", align: "left", value: "mt_appoin_day" },
        { text: "เดือนบรรจุ", align: "left", value: "mt_appoin_month" },
        { text: "ปีบรรจุ", align: "left", value: "mt_appoin_year" },
        { text: "คำสั่ง", align: "left", value: "mt_order_app" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      manage_assistant_teacherstatus: [],
      regions: [],
      region_ena: true,
      data_syslog: {},
      branchs: [],
      periodassteachs: [],
      headersCount: [
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัส", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "branchName" },
        { text: "จำนวน", align: "left", value: "branchGroup" },
        { text: "แสดง", align: "left", value: "actions" }
      ],
      manage_assistant_teacherCount: [],
      mt_status_select: [
        { text: "ปกติ", value: "normal" },
        { text: "สละสิทธิ์", value: "disclaim" }
      ],
      mt_examtype_select: [
        { text: "คัดเลือก(ภายใน)", value: "select" },
        { text: "ทั่วไป(ภายนอก)", value: "recruit" }
      ],
      mt_examgroup_select: [
        { text: "ทั่วไป", value: "general" },
        { text: "ชายแดนภาคใต้", value: "southern" }
      ],
      type_personnelApp: [     
        { text: "ครูพัฒนาท้องถิ่น", value: "local_dev" },
        { text: "ครูโอนย้าย", value: "transfer_position" }
      ],
      updatemanage_assistant_teachers: {},
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu1: false,
      menu2: false
    };
  },
  async mounted() {
    await this.periodassteachQuery();
    await this.manage_assistant_teacherQueryAll();
    await this.branchQuery();
    await this.matCountQuery();
  },
  methods: {
    async updatemanage_assistant_teachersDate() {
      this.updatemanage_assistant_teachers.ApiKey = this.ApiKey;
      this.updatemanage_assistant_teachers.mt_times = this.periodassteachs.periodAssTeachTimes;
      this.updatemanage_assistant_teachers.mt_years = this.periodassteachs.periodAssTeachYear;
      let result = await this.$http.post(
        "manage_assistant_teacher.updateadmin.php",
        this.updatemanage_assistant_teachers
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.manage_assistant_teacherQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
 
    async matBranchQuery(mt_times, mt_years, mt_id_branch) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: mt_times,
        mt_years: mt_years,
        mt_id_branch: mt_id_branch
      });
      this.manage_assistant_teachers = result.data;
      this.manage_assistant_teacherCountDialog = false;
    },
    async matCountQuery() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: this.periodassteachs.periodAssTeachTimes,
        mt_years: this.periodassteachs.periodAssTeachYear,
        mt_count: "Ok"
      });
      this.manage_assistant_teacherCount = result.data;
    },
    async periodassteachQuery() {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnablePro: "1"
      });
      this.periodassteachs = result.data;
    },
    async branchQuery() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branchs = result.data;
    },
    async csvManpowerSubmit() {
      let result = "";
      let uploaded = null;
      if (this.file2) {
        let formData = new FormData();
        let filename = this.time_stamp + ".manpower.csv";
        formData.append("file", this.file2);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post(
          "manage_assistant_teacherCSV.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.manage_assistant_teacherQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
        this.manage_assistant_teacherQueryAll();
      }
      this.manage_assistant_teacherCSVdialog = false;
    },
    async manage_assistant_teacherCSV() {
      this.manage_assistant_teacherCSVdialog = true;
    },
    async manage_assistant_teacherTotal() {
      await this.matCountQuery();
      this.manage_assistant_teacherCountDialog = true;
    },
    async sweetAlertLoading() {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then(result => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          
        }
      });
    },
    async manage_assistant_teacherReplace() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          manage_assistant_teacherCheck: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },
    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          report_selete: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },
    async manage_assistant_teacherBranchIDQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_id_branch: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },
    async manage_assistant_teacherAdd() {
      this.addmanage_assistant_teacher = {};
      this.addmanage_assistant_teacherdialog = true;
    },
    async addmanage_assistant_teacherSubmit() {
      if (this.$refs.addmanage_assistant_teacherform.validate()) {
        this.addmanage_assistant_teacher.ApiKey = this.ApiKey;
        this.addmanage_assistant_teacher.mt_times = this.periodassteachs.periodAssTeachTimes;
        this.addmanage_assistant_teacher.mt_years = this.periodassteachs.periodAssTeachYear;
        let result = await this.$http.post(
          "manage_assistant_teacher.insert.php",
          this.addmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการบันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        }
        this.addmanage_assistant_teacherdialog = false;
      }
    },
    async manage_assistant_teacherEdit(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.editmanage_assistant_teacherdialog = true;
    },
    async editmanage_assistant_teacherSubmit() {
      if (this.$refs.editmanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        if (this.editmanage_assistant_teacher.mt_status == "normal") {
          this.editmanage_assistant_teacher.mt_status == "";
        }
        let result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editmanage_assistant_teacherdialog = false;
      }
    },
    async manage_assistant_teacherDelete(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.deletemanage_assistant_teacherdialog = true;
    },
    async deletemanage_assistant_teacherSubmit() {
      if (this.$refs.deletemanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "manage_assistant_teacher.delete.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "manage_assistant_teacher";
          this.data_syslog.table_log = "manage_assistant_teacher";
          this.data_syslog.detail_log =
            this.editmanage_assistant_teacher.id_position +
            " : " +
            this.editmanage_assistant_teacher.college_code +
            " : " +
            this.editmanage_assistant_teacher.case_vacancy;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletemanage_assistant_teacherdialog = false;
      }
    },
    row_classes(item) {
      if (parseInt(item.mt_college_code) >= 1) {
        return "light-green";
      } else {
        return "amber";
      }
    }
  },
  computed: {
    color() {
      return "blue-grey";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { ManageAssistantTeacherBar }
};
</script>

<style lang="scss" scoped></style>
